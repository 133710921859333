import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { FOOTER_DATA } from '../../data/index';
import React from 'react';
import { FooterArea, CopyrightContainer } from './footer.style';

const Footer = () => {
  const { logo, menu, social, strideLogo } = FOOTER_DATA;
  return (
    <FooterArea>
      <Container>
        <Box className="logoBox">
          <a className="logo">
            <img src={ logo } alt="logo footer" />
          </a>
        </Box>
        <Box className="social">
          <Text as="span" content={ <>32-1-53, BOWDARA ROAD, VISAKHAPATNAM - 530004</> } />
          <Text as="span" content={ <><br />+91-7799777175</> } />
          <Text as="span" content={ <><br />info@unitydegreecollege.com</> } />
        </Box>
        <Box className="logoBox">
          <a className="logo">
            <img src={ strideLogo } alt="logo footer" />
          </a>
        </Box>
      </Container>
      <CopyrightContainer>
        <Text as="span" content={ <><br />Copyright: © Copyright 2024 Unity College || All Rights Reserved.</> } />
      </CopyrightContainer>
    </FooterArea>
  );
};

export default Footer;
