import styled from 'styled-components';

export const ContactUsWrapper = styled.section `
  position: relative;
  padding-top : 60px;
  padding-bottom : 60px;
  .container {
    max-width: 1250px;
  }
  .contactInner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 52.5px;
    padding-bottom: 52.5px;
    border-radius : 5px;

    @media (max-width: 991px) {
      flex-direction: column;
      jusitfy-content: flex-start;
      align-items: flex-start;
    }

    @media (max-width: 480px) {
      padding: 40px;
    }
    @media (max-width: 375px) {
    }

    &:before {
      content: '';
      background-color : #182C59;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    h3 {
      margin: 0;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 1.41;
      letter-spacing: -1px;
      color: #ffffff;
      @media (max-width: 1440px) {
        font-size: 28px;
      }
      &.color2 {
        color: #ffffff;
        letter-spacing: 0.3px;
        margin-top: 5px;
        font-size : 14px;
        font-weight: 400;
      }

      @media (max-width: 575px) {
        font-size: 28px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    .leftContent{
      display: flex;
      flex-direction: column;
      gap:10px;
      width:40%;

      @media (max-width: 575px) {
        width:100%;
      }
    }
  }
  .contactBtn {
    display: inline-block;
    position: relative;
    background-color : #F7D131;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: black;
    padding: 15px, 35.44px, 15px, 34px;
    @media (max-width: 1440px) {
      font-size: 16px;
      padding: 17px 37px;
    }
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
`;