import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../common/components/Navbar/index';
import Drawer from '../../common/components/Drawer/index';
import Button from '../../common/components/Button/index';
import Box from '../../common/components/Box';
import HamburgMenu from '../../common/components/HamburgMenu';
import Container from '../../common/components/UI/Container';
import { DrawerContext } from '../../common/contexts/DrawerContext';
import Text from '../../common/components/Text';
//import { NavbarWrapper } from './navbar.style';
//import { MENU_ITEMS } from 'common/data/SaasClassic';
//import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

//import LogoImage from 'common/assets/image/saasClassic/logo-white.png';
//import LogoImageAlt from 'common/assets/image/saasClassic/logo.png';

const Navbar = ( { navbarStyle, logoStyle, button, row, menuWrapper, mainText, containerStyle } ) => {
  const { state, dispatch } = useContext( DrawerContext );

  // Toggle drawer
  const toggleHandler = () => {
    dispatch( {
      type: 'TOGGLE',
    } );
  };

  const navigateToTest = () => {
    window.open( 'https://quiz.unitydegreecollege.com/unitycollege/survey/T70X3SRpoFi', '_blank' );
  };


  return (
    <NavbarWrapper { ...navbarStyle } className="saas_navbar">
      <Box { ...row }>
        {/*
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="#"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          */}
        <Text content="💡 Get Your Personalised Report in Less than 2 Mins." { ...mainText } />
        <Box { ...menuWrapper }>
          {/*<ScrollSpyMenu
              className="main_menu"
              menuItems={MENU_ITEMS}
              offset={-70}
            />*/}
          <a href="#">
            <a className="navbar_drawer_button">
              <Button { ...button } title="download your Report" onClick={ navigateToTest } />
            </a>
          </a>
        </Box>
      </Box>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  mainText: PropTypes.object,
  containerStyle: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  containerStyle: {
    pr: '16px',
    pl: '16px',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    pl: ['16px', '30px', '100px', '200px', '290px'],
    pr: ['16px', '30px', '100px', '200px', '290px'],
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    backgroundColor: '#edcd37',
    color: 'black',
    minHeight: 'auto',
    height: '40px',
  },
  mainText: {
    color: 'white',
    fontSize: ['12px', '16px', '18px', '20px', '24px'],
    fontWeight: '700',
    mb: '0',
    mr: '10px',
    lineHeight: '1.3',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
