import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';

import {
  MONTHLY_PRICING_TABLE,
  YEARLY_PRICING_TABLE,
} from '../../data/index';

import PricingTable, {
  PricingHead,
  PricingPrice,
  ButtonBooking,
  WrapperRow,
  PricingTableWrapper,
} from './pricing.style';

const PricingSection = ( {
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonFillStyle,
  listContentStyle,
  sectionWrapperCol,
  buttonBox,
} ) => {
  const [state, setState] = useState( {
    data: MONTHLY_PRICING_TABLE,
    active: true,
  } );

  const [loading, setLoading] = useState( false );
  useEffect( () => {
    setTimeout( function () {
      setLoading( true );
    }, 500 );
  } );

  const data = state.data;
  const activeStatus = state.active;

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        type: 'carousel',
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        type: 'carousel',
        perView: 1,
        peek: {
          before: 100,
          after: 100,
        },
      },
      767: {
        type: 'carousel',
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        type: 'carousel',
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  const DownloadReport = () => {
    window.open( 'https://stride-survey-assets.s3.amazonaws.com/unitycollege/samplePDF', '_blank' );
  };

  return (
    <Box { ...sectionWrapper } id="pricing_section">
      <Container>
        <Box { ...secTitleWrapper }>
          <Heading
            { ...secHeading }
            content="Want to unlock insights? Get free personalised recommendations? Figure out in 3 easy steps."
          />
          <Text { ...secText } content="You are a right fit to take the test if" />
        </Box>
        <WrapperRow>
          <>
            { data.map( ( pricingTable, index ) => (
              <Box { ...sectionWrapperCol } key={ `pricing-table-${ index }` }>
                <PricingTable
                  freePlan={ pricingTable.freePlan }
                  className="pricing_table"
                >
                  <PricingHead>
                    <Heading content={ pricingTable.name } { ...descriptionStyle } />
                    <Text
                      content={ pricingTable.description }
                      { ...nameStyle }
                    />
                  </PricingHead>
                  <PricingPrice>
                    <Text content={ pricingTable.price } { ...priceStyle } />
                  </PricingPrice>
                </PricingTable>
              </Box>
            ) ) }
          </>
        </WrapperRow>
        <ButtonBooking>
          <Button onClick={ DownloadReport } className="contactBtn" title="Download Sample Report" />
        </ButtonBooking>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
  sectionWrapperCol: PropTypes.object,
  buttonBox : PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px', '50px'],
    pb: '50px',
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  sectionWrapperCol: {
    flex: '1 1 33.333%',
    pl: '20px',
    pr: '20px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight:'500',
    color: '#3E3E3E',
    mt: '22px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '70%',
    maxWidth: '100%',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '16px', '16px', '16px'],
    fontWeight: '700',
    color: '#182C59',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '22px', '22px', '22px'],
    color: '#0F2137',
    lineHeight: '1.75',
    textAlign: 'center',
    fontWeight: '700',
    mb: '25px',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#6E7379',
    textAlign: 'center',
    mb: '-5px',
    fontWeight: '400',
    lineHeight: '26px',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#2aa275',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '15px',
    fontWeight: '700',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    minWidth: ['160px', '190px'],
    maxWidth: '100%',
    height: '48px',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    mb: '0',
  },
};

export default PricingSection;
