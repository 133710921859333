import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';
import { TESTIMONIALS } from '../../data/index';
import aboutTheTestImage from '../../common/assets/OBJECTS.png';
import {
  TestimonialItem,
  WrapperRow,
  ImageWrapper,
} from './testimonial.style';
import { Image } from 'antd';

const TestimonialSection = ( {
  sectionWrapper,
  reviewTitle,
  review,
  sectionWrapperRow,
  image,
  sectionWrapperCol,
} ) => {

  return (
    <Box { ...sectionWrapper } as="section" id="testimonial_section">
      <Container>
        <WrapperRow>
          <>
            {/*{ TESTIMONIALS.map( ( item, index ) => (
              <Box { ...sectionWrapperCol } key={ `testimonial-slide-${ index }` }>
                <TestimonialItem>
                  <Heading as="h3" content={ item.title } { ...reviewTitle } />
                  <Text content={ item.review } { ...review } />
                </TestimonialItem>
              </Box>
            ) ) }*/}
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-1` }>
              <TestimonialItem>
                <Heading as="h3" content='About Quiz: Career Readiness Quiz ' { ...reviewTitle } />
                <Text content={ <>This is a measure of your career readiness and is designed to higlight your Strengths, Areas of Improvements & Recommend you the best suited career for you to be job ready.</> }
                  { ...review } />
                <Text content={ <><br /><strong>1. Quick & easy</strong> - Complete in 30 secs</> }
                  { ...review } />
                <Text content={ <><br /><strong>2. Personalized recommendation</strong> to help you choose a career you like</> }
                  { ...review } />
                <Text content={ <><br /><strong>3. Gain insights & understand yourself</strong></> }
                  { ...review } />
              </TestimonialItem>
            </Box>
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-2` }>
              <TestimonialItem>
                <ImageWrapper>
                  <Image src={ aboutTheTestImage } alt="iamge" preview={ false } />
                </ImageWrapper>
              </TestimonialItem>
            </Box>

          </>
        </WrapperRow>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapperRow: PropTypes.object,
  sectionWrapperCol: PropTypes.object,
  image: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '0px'],
    pb: ['60px', '80px', '90px', '100px', '60px'],
  },
  image: {
    pt: ['5px', '10px', '20px', '20px', '20px'],
  },
  sectionWrapperRow: {
    display: 'flex',
    flexwrap: 'wrap',
    ml: '-20px',
    mr: '-20px',
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  sectionWrapperCol: {
    flex: '1 1 33.333%',
    pl: '20px',
    pr: '20px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  reviewTitle: {
    fontSize: ['15px', '24px'],
    fontWeight: '800',
    color: '#182C59',
    lineHeight: '1.5',
    mb: '20px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default TestimonialSection;
